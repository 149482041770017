.btn-primary,
.btn-primary:hover,
.btn-primary:active,
.btn-primary:focus,
.btn-primary:focus:active,
.btn-primary:not(:disabled):not(.disabled):active,
.btn-primary:not(:disabled):not(.disabled):active:focus,
.btn-primary:visited,
.swal-button.swal-button--confirm,
.swal-button.swal-button--confirm:hover,
.swal-button.swal-button--confirm:active,
.swal-button.swal-button--confirm:focus,
.swal-button.swal-button--confirm:focus:active,
.swal-button.swal-button--confirm:not(:disabled):not(.disabled):active,
.swal-button.swal-button--confirm:not(:disabled):not(.disabled):active:focus,
.swal-button.swal-button--confirm:visited {
  background: #0cc0de;
  border-color: #0cc0de;
}

.btn-outline-primary{
  border-color: #0cc0de;
  background-color: white;
  color: #0cc0de;
}
.btn-outline-primary:hover{
  background: #0cc0de;
color: white;
border-color: #0cc0de;
}

.btn-primary:hover,
.btn-primary:focus,
.btn-primary.disabled,
.btn-primary:disabled,
.swal-button.swal-button--confirm:hover,
.swal-button.swal-button--confirm:focus {
  background: #0cc0de;
  border-color: #0cc0de;
  opacity: 0.8;
}

.btn-danger,
.btn-danger:hover,
.btn-danger:active,
.btn-danger:focus,
.btn-danger:focus:active,
.btn-danger:not(:disabled):not(.disabled):active,
.btn-danger:not(:disabled):not(.disabled):active:focus,
.btn-danger:visited {
  background: #D8F7FF;
  border-color: #221f20;
  color: #221f20;
}

.btn-danger:hover,
.btn-danger:focus {
  opacity: 0.8;
}

:root{
  --primary-color: #0cc0de;
  --white: #D8F7FF;
  --primary-accent: #D8F7FF;
  --black: #221f20;
}

.sk-three-bounce .sk-child {
  background-color: #0cc0de;
}

.header-left input {
  background-color: initial !important;
}

.location-select {
  width: 300px;
}

.bordered-form-box .form-group {
  margin-bottom: 0px;
}

.bordered-form-box .form-title {
  margin-top: 24px;
  margin-bottom: 4px;
}

.form-control-location {
  min-width: 300px;
}

.form-control-location .css-e56m7-control,
.form-control-location .css-pc7y0b-control {
  border-radius: 6px;
  border: 1px solid #f0f1f5;
  color: #6e6e6e;
  height: 56px;
  font-size: 14px;
  padding-left: 6px;
}

.text-primary {
  color: #0cc0de !important;
}

[data-headerbg="color_1"] .nav-header .hamburger.is-active .line,
[data-headerbg="color_1"] .nav-header .hamburger .line,
[data-sidebar-style="full"][data-layout="vertical"]
  .menu-toggle
  .nav-header
  .nav-control
  .hamburger
  .line,
.header-right .header-profile > a.nav-link,
[data-sidebar-style="full"][data-layout="vertical"]
  .menu-toggle
  .deznav
  .metismenu
  > li.mm-active:hover
  > a
  i {
  background: #0cc0de !important;
  color: white;
}

[data-sidebar-style="full"][data-layout="vertical"]
  .menu-toggle
  .deznav
  .metismenu
  > li.mm-active
  > a {
    background: #e4dada !important;
  }

[data-sidebar-style="full"][data-layout="vertical"]
  .deznav
  .metismenu
  > li.mm-active
  > a,
[data-sidebar-style="full"][data-layout="vertical"]
  .menu-toggle
  .deznav
  .metismenu
  > li:hover
  > a {
  background-color: #D8F7FF;
}

[data-sidebar-style="full"][data-layout="vertical"]
  .deznav
  .metismenu
  > li.mm-active
  > a,
[data-sidebar-style="full"][data-layout="vertical"]
  .deznav
  .metismenu
  > li
  > a:hover,
[data-sidebar-style="full"][data-layout="vertical"]
  .deznav
  .metismenu
  > li.mm-active
  > a
  i,
[data-sidebar-style="full"][data-layout="vertical"]
  .deznav
  .metismenu
  > li
  > a:hover
  i,
[data-sidebar-style="full"][data-layout="vertical"]
  .menu-toggle
  .deznav
  .metismenu
  > li:hover
  > a
  i {
  color: #0cc0de;
}

[data-sidebar-style="full"][data-layout="vertical"]
  .deznav
  .metismenu
  > li
  > a:before {
  background-color: #0cc0de;
}

.deznav .metismenu ul a:hover, 
.deznav .metismenu ul a:focus, 
.deznav .metismenu ul a.mm-active {
  color: #0cc0de;
}

.deznav .metismenu .las {
  font-size: 24px;
}

.nav-header .brand-title {
  margin: auto;
}

.nav-header .logo-abbr {
  display: none;
  max-width: 40px;
}

.header-right .header-profile .dropdown-menu a:hover,
.header-right .header-profile .dropdown-menu a:focus,
.header-right .header-profile .dropdown-menu a.active {
  color: #0cc0de;
}

.dropdown-item.active,
.dropdown-item:active {
  background-color: #D8F7FF;
}

.header-right .notification_dropdown .nav-link.warning {
  color: #0cc0de;
  padding: 10px;
}

.header-right .notification_dropdown .nav-link i {
  font-size: 32px;
}

td.menu-arrow-box {
  width: 60px;
  padding: 0px !important;
}

.menu-arrow {
  width: 60px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
  color: #7e7e7e;
  cursor: pointer;
}

.menu-arrow:hover {
  background-color: #D8F7FF;
  color: #0cc0de;
}

.menu-arrow:hover a,
a:hover {
  color: #0cc0de;
}

.submenu-hours.menu-arrow {
  border-radius: 12px;
  height: 56px;
  margin-right: -13px;
}

.items-table tbody tr.active,
.items-table tbody tr:hover {
  box-shadow: 0px 4px 4px 0 rgb(0 0 0 / 12%);
}

.switch .switch-group .btn {
  padding: 4px 0px;
}

.switch-btn-sm-width .switch.btn {
  width: 4rem;
}

.td-sm {
  width: 1px;
}

.btn.tp-btn-light.btn-primary,
.btn.tp-btn-light.btn-primary g [fill],
.btn.tp-btn-light.btn-primary:hover g [fill] {
  fill: #0cc0de;
}

.btn.tp-btn-light.btn-primary:hover,
.show > .btn-primary.dropdown-toggle {
  background-color: #D8F7FF;
  color: #0cc0de;
  border-color: #D8F7FF;
}

.btn-primary:not(:disabled):not(.disabled):active g [fill] {
  fill: white;
}

.date-range-dropdown {
  display: inline-block;
  margin-bottom: 1rem;
  /* width: 236px; */
  width: auto;
}



.light.btn-primary {
  color: #0cc0de;
  background-color: #D8F7FF;
  border-color: #D8F7FF;
}

.light.btn-primary g [fill] {
  fill: #0cc0de;
}

.light.btn-primary:hover {
  background-color: #0cc0de;
  border-color: #0cc0de;
  color: white;
}

.light.btn-primary:hover .light.btn-primary g [fill] {
  color: white;
}

.dataTables_wrapper .dataTables_paginate .paginate_button.previous:hover,
.dataTables_wrapper .dataTables_paginate .paginate_button.next:hover {
  background: #0cc0de;
}

.dataTables_wrapper .dataTables_paginate .paginate_button {
  background: none;
  margin: 0px 2px;
  padding: 0px;
}

.dataTables_wrapper .dataTables_paginate .paginate_button a {
  color: #0cc0de !important;
  padding: 8px 16px;
  display: inline-block;
}

.dataTables_wrapper .dataTables_paginate .paginate_button.current,
.dataTables_wrapper .dataTables_paginate .paginate_button:hover {
  background: #0cc0de !important;
  cursor: pointer;
}

.dataTables_wrapper .dataTables_paginate .paginate_button.current a,
.dataTables_wrapper .dataTables_paginate .paginate_button:hover a {
  color: white !important;
}

.footer .copyright a {
  color: #0cc0de;
}

.card-tabs .nav-tabs {
  background: #D8F7FF;
}

.form-title {
  color: #7e7e7e;
  font-weight: 500;
  margin-bottom: 16px;
}

.expand-collapse-title {
  background: #f7f8f8;
}

.form-control:focus {
  border-color: #0cc0de;
}

.timepicker-container,
.MuiFormControl-root.MuiTextField-root {
  flex: initial;
  height: 56px;
  width: 126px;
}

.timepicker-container,
.MuiFormControl-root.MuiTextField-root .MuiInputBase-input {
  text-align: right;
}

.MuiPickersToolbar-toolbar {
  background-color: #0cc0de !important;
}

.MuiButton-textPrimary {
  color: #0cc0de !important;
}

.MuiInput-underline:after {
  border-bottom-color: #0cc0de !important;
}

.MuiPickersClockPointer-pointer,
.MuiPickersClockPointer-noPoint,
.MuiPickersClock-pin {
  background-color: #0cc0de !important;
}

.MuiPickersClockPointer-thumb {
  border-color: #0cc0de !important;
}

.pac-container {
  z-index: 9999;
}

.menu-toggle .save-cancel {
  width: calc(100% - 5rem);
}

.save-cancel {
  position: fixed;
  bottom: 0;
  right: 0;
  width: calc(100% - 21.563rem);
  z-index: 9;
  background: white;
}

[data-sidebar-style="overlay"] .save-cancel {
  width: 100%;
}

.modal.flex-modal {
  display: flex !important;
  visibility: hidden;
  justify-content: center;
  align-items: center;
}

.modal.flex-modal.show {
  visibility: visible;
}

.modal.flex-modal .modal-dialog {
  margin: 0px;
  width: 500px;
}

.modal-content {
  border: none;
  box-shadow: 3px -3px 9px 0px rgb(0 0 0 / 20%);
}

.menu-toggle .loading-overlay {
  width: calc(100% - 5rem);
}

.loading-overlay {
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  /* width: 100%; */
  width: calc(100% - 21.563rem);
  margin: -30px 0 0 -30px;
  height: calc(100vh - 120px);
  background: rgba(255, 255, 255, 0.5);
  z-index: 9;
}

.w-tax-per {
  width: 76px !important;
}

.custom-checkbox {
  cursor: pointer;
}

.custom-control-input:checked ~ .custom-control-label::before,
input[type="checkbox"]:checked:after {
  border-color: #0cc0de;
  background-color: #0cc0de;
  cursor: pointer;
}

.custom-control-label {
  cursor: pointer;
}

input[type="checkbox"]:checked:after,
input[type="checkbox"]:after {
  width: 24px;
  height: 24px;
  padding-top: 4px;
}

.report-scrollable {
  max-height: 420px;
  overflow-y: scroll;
}

/* mobile responsive */
.deznav .metismenu > li.mm-active > a,
.deznav .metismenu > li:hover > a,
.deznav .metismenu > li:focus > a {
  color: #0cc0de;
}

.bottom-menu {
  display: none;
  position: absolute;
  bottom: 12px;
  width: 100%;
}

.image-wrapper {
  width: 160px;
  height: 80px;
  border-radius: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.user-icon {
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.table.table-striped tbody tr:nth-of-type(odd),
.table.table-hover tr:hover {
  background-color: #D8F7FF69;
}

.profile-photo {
  margin-top: 0px !important;
}

.profile-info h4.text-primary {
  color: #0cc0de !important;
  margin-bottom: 5px !important;
}

.badge-primary {
  background-color: #D8F7FF;
  color: #0cc0de;
}

.profile-tab .nav-item .nav-link:hover,
.profile-tab .nav-item .nav-link.active {
  border-color: #0cc0de;
  color: #0cc0de;
}

.list-group-item .la-pen:hover {
  color: #0cc0de;
}

.list-group-item .group-icon-img {
  margin-right: 18px;
}

.which-input {
  max-width: 100px !important;
}

.date-label {
  min-width: 100px;
  text-align: left;
  font-size: 14px;
  padding: 0;
  margin: 0;
}

.status-img {
  position: absolute;
  width: 16px;
  height: 16px;
  bottom: -2px;
  right: -4px;
}

.status-img-lg {
  position: absolute;
  width: 24px;
  height: 24px;
  bottom: -2px;
  right: -4px;
}

.recharge-button {
  background-color: #0cc0de;
  color: white;
  transition: all 200ms ease-in-out;
  font-size: 12px;
  padding: 5px 10px;
  height: 40px;
}
.recharge-button:hover {
  transform: scale(1.02);
  color: white;
}

@media only screen and (max-width: 1199px) {
  .nav-header .logo-abbr {
    display: block;
  }
}

@media only screen and (max-width: 768px) {
  .loading-overlay {
    width: 100% !important;
    margin-left: -15px;
  }

  .save-cancel {
    width: 100%;
  }

  .save-cancel .btn {
    width: 35% !important;
  }

  .bottom-menu {
    display: block;
  }

  .header-right .header-profile > a.nav-link {
    background-color: transparent !important;
  }

  .date-range-dropdown {
    width: 100%;
    margin-bottom: 0px;
  }

  .date-range-dropdown .text-end {
    margin-right: auto !important;
  }

  .date-range-dropdown .dropdown-menu.show {
    width: 100%;
    box-shadow: 3px -3px 9px 0px rgb(0 0 0 / 20%);
  }

  .rdrDateRangePickerWrapper {
    flex-direction: column;
    width: 100%;
  }

  .rdrDefinedRangesWrapper,
  .rdrMonth {
    width: 100%;
  }

  .card-tabs .nav-tabs {
    flex-wrap: wrap;
  }

  .nav-tabs .nav-item {
    /* width: 33%; */
    text-align: center;
  }

  .report-tabs.nav-tabs .nav-item {
    width: 50%;
  }

  .report-cards .rounded-xl {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row-reverse;
  }

  .report-cards .rounded-xl h2 {
    font-size: 24px !important;
    margin: 0px;
  }

  .location-dropdown span {
    font-size: 14px;
    max-width: 65px;
    margin-top: 2px;
    text-overflow: ellipsis;
    display: inline-block;
    white-space: nowrap;
    overflow: hidden;
  }

  .location-select {
    width: 100%;
  }

  .header-left .search_bar {
    display: block;
    position: fixed;
    top: 80px;
    right: -100%;
    width: 100%;
    height: auto;
    transition: 0.1s;
  }

  .header-left .search_bar.mobile-show {
    right: 0px;
    z-index: 999;
  }

  .header-left .search_bar .dropdown-menu,
  .header-left .search_bar .dropdown-menu.show {
    width: 100%;
    left: 0px;
  }

  .dataTables_wrapper .dataTables_paginate a {
    padding: 2px 8px !important;
  }

  h1,
  h2,
  h3,
  .h1,
  .h2,
  .h3 {
    font-size: 18px;
  }

  .h4,
  .h5,
  .h6,
  h4,
  h5,
  h6 {
    font-size: 14px;
  }

  .add-contact-content .form-control {
    width: 100% !important;
    margin-top: 6px;
  }

  .expand-collapse-title {
    padding: 0px 0.5rem !important;
  }

  .menu-arrow {
    height: 44px;
    width: 44px;
  }

  .menu-arrow i {
    font-size: 24px !important;
  }

  .submenu-hours.menu-arrow {
    height: 44px;
  }

  .timepicker-container,
  .MuiFormControl-root.MuiTextField-root {
    height: 40px;
  }

  .form-head {
    display: flex !important;
    align-items: center !important;
  }

  .form-head > * {
    margin-right: inherit !important;
  }

  .form-head .mr-auto {
    margin-right: auto !important;
  }

  .profile-tab .nav-tabs {
    width: 100%;
  }

  .profile-tab .nav-item .nav-link,
  .profile-tab .nav-item .nav-link {
    font-size: 14px;
    margin: 0px;
  }

  .profile .profile-info {
    padding: 0px;
  }

  .profile .profile-photo {
    margin-bottom: 0px;
  }

  .profile .profile-info .profile-details {
    padding-right: 40px;
    text-align: left;
  }

  .profile .profile-info .profile-details .profile-email {
    padding-left: 1rem !important;
    padding-bottom: 0.75rem;
  }

  .profile .profile-info .profile-details .back-btn {
    position: absolute;
    top: 5px;
    right: 20px;
  }
}

.text-underline {
  text-decoration: underline !important;
}

.cursor-pointer{
  cursor: pointer;
}
.capitalize {
  text-transform: capitalize;
}
.object-fit-cover{
  object-fit: cover;
}
.object-fit-contain{
  object-fit: contain;
}
.gap-1{
  gap: 5px;
}
.gap-2{
  gap: 10px;
}
.gap-3{
  gap: 16px
}
.relative{
  position: relative;
}
.absolute{
  position: absolute;
}
.pointer{
  cursor: pointer;
}
a{
  text-decoration: none !important;
}
.tab-container {
  width: max-content;
}


/* Custom classes */
.global-input-field {
  font-size: 14px;
  color: #333; /* Dark gray text color */
  border: 1px solid rgb(223, 222, 222); /* Light gray border */
  border-radius: 6px;
  padding: 8px 12px;
  box-shadow: 0 1px 2px rgba(0, 0, 10, 0.1); /* Slight elevation with shadow */
}


.document-card{
  max-width: 400px;
  border: 1px solid rgb(223, 222, 222);
  border-radius: 8px;
  padding: 20px  10px;
  color: rgb(62, 61, 61);
  margin-bottom: 10px;
}

.appointment-card{
  width: 100%;
  min-height: 100px;
  background-color: white;
  padding: 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  margin-bottom: 1rem;
}
.appointment-card:hover{
  background-color: #D8F7FF;

}
.appointment-card .appointment-card_label{
  font-size: 14px;
}

.appointment-card .appointment-card_field{
  color: rgb(39, 38, 38);
  font-weight: 500;
}

.appointment-card .appointment-card_img{
  width: 30px;
  height: 30px;
  object-fit: cover;
  border-radius: 50%;
  border: 1px solid #333;
  margin-right: .5rem;
}

.appointment-details-img{
  width: 100px;
  height: 100px;
  border-radius: 10px;
  border: 1px solid gray;
}

.signup-img{
  border-radius: 50%;
  border: 1px solid gray;
  position: relative;
}

.edit-img-icon{
  bottom: 0;
  right: -5;
  cursor: pointer;
}
.profile-img-container{
  width: max-content;
  margin: auto;
}

.pharma-card{
  border: 1px solid rgba(191, 191, 191, 0.481);
  border-radius: 5px;
  width: 100%;
  margin-bottom: 10px;
  cursor: pointer;
  max-height: 60px;

}
.pharma-card:hover, .pharma-card_selected{
  transform: scale(1.03);
  background-color: var(--primary-accent);
  /* color: white; */
}
.pharma-card_name{
  font-weight: 600;
  font-size: 16px;

}

.pharma-card_distance{
  /* font-family: 'Courier New', Courier, monospace; */
  font-weight: 600;
  font-size: 14px;
}
.pharma-card_description{
  font-size: 13px;
}
.no-scroll{
  overflow-y: hidden !important;
}

.chat-page{
  display: flex;
  align-items: start;
  gap: 1rem;
  height: calc(100vh - 170px);
  overflow-y: hidden;
}

.no-conversation-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  background-color: #f5f5f5; /* Light gray background color */
}

.message-container {
  text-align: center;
}

.message {
  font-size: 18px;
  font-weight:500;
  color: #555;
}

.chat-list{
  width: 340px;
  height: 100%;
  /* background-color: green; */
  border-radius: 12px;
  padding: 1rem 0;
}

.message-list, .message-list-no-chat{
  flex-grow: 1;
  height: 100%;
  /* background-color: rgb(157, 157, 219); */
  border-radius: 12px;
}
.message-list-no-chat{
 padding: 1rem;
}
.message-header{
  padding: 1rem;
  height: 70px;
  border-radius: 12px  12px 0 0;
 border-bottom: .5px solid rgb(178, 178, 178);
 margin-bottom: 0;

}
.chat-card{
 height: 90px;
 border-bottom: .5px solid rgb(220, 220, 220);
 padding: .5rem;
 border-radius: 0;
 /* background-color: #d9d9d942; */
 margin-bottom: 0;
 display: flex;
 align-items: center;
 flex-direction: row;
 cursor: pointer;
}
.chat-card:hover, .chat-card.selected{
  background-color: var(--primary-accent);
}

.chat-input-container{
  height: 100px;
  /* background-color: rgb(182, 182, 235); */
  border-radius: 0 0 12px  12px;  
  display: flex;
  align-items: center;
  padding: 1rem;
  gap: 1rem;
}
.chat-input-container_button{
  padding: .65rem 1rem;
}
.chat-input-container_input{
  padding: .5rem 1rem;
  height: 50px;
  border-color: #e6e6e6;
  box-shadow: none !important;
}
.message-list{
  display: flex ;
  flex-direction: column;
  gap: 0;
  height: 100%;
  justify-content: space-between;
 }
.message-list-scroll{
  flex-grow: 1;
  padding: 1rem 1rem 0rem 1rem;
  scroll-behavior: smooth;
  scroll-snap-type: y mandatory;
  scroll-snap-align: center;
  scroll-snap-stop: always;
  overflow-y: scroll;
  background-color: #f1f4f4;
}

.message-header{
  display: flex;
  align-items: start;
}
.message-header_image{
  width: 40px;
  height: 40px;
  border-radius: 50%;
}


.header_name{
  color: #000;

}
.header_state, .header_time, .header_unread{
  font-size: 12px;
}
.header_unread{
   width: 20px;
   height: 20px;
   border-radius: 50%;
   display: flex;
   align-items: center;
   justify-content: center;
   background-color: #2e2e2e;
   color: white;
}

.message-base {
  width: max-content;
  height: auto;
  padding: 10px;
  font-size: 14px;
  max-width: 600px;
  word-wrap: break-word;
  color: #000;
  /* // background-color: var(--primary-color); */
}
.message-time{
  font-size: 10px;
}
.my-message-border-radius {
  border-radius: 16px 0 16px 16px !important;
}

.message-border-radius {
  border-radius: 0 16px 16px 16px !important;
}

.card-icon{
  width: 50px;
  height: 50px;
  border-radius: 6px;
  padding: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgb(226, 226, 226) !important;
}
.content-container{
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.appointment-card-container {
  overflow-x: scroll; 
  display: flex;
  max-width: 100%;
}

.appointment-card {
  min-width: 600px;
  flex-shrink: 0;
}

.order-status-stepper {
  display: flex;
  flex-direction: column;
  align-items: start;
}

.step {
  display: flex;
  align-items: center;
  margin-bottom: 1.5rem;
  font-size: 14px;
  font-family: 'Courier New', Courier, monospace;
  font-weight: 600;
}

.step-circle {
  height: 1.5rem;
  width: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid #ccc;
  border-radius: 50%;
  position: relative;
}

.step-circle.completed {
  background-color: #6ee7b7; /* Green color for completed steps */
  border-color: #6ee7b7;
}

.step-number {
  color: #666;
}

.step-label {
  margin-left: 1rem;
  color: #666;
}

.step-label.completed {
  color: #6ee7b7; /* Green color for completed steps */
}

.checkmark {
  width: 1.5rem;
  height: 1.5rem;
  color: white;
}

.order-status-select{
  max-width: 200px;
}

.form-control.input-search{
	height:38px;
	border-radius:8px;
	margin-top:8px;
	margin-bottom:8px;
}
.input-search{
	height:38px;
	border-radius:8px;
	margin-top:8px;
	margin-bottom:8px;
	border:1px solid #eee;
	padding: 0 8px;
}


.filtering-table thead th:first-child{
	width:70px;
}
.table tfoot tr {
    border-bottom-width: 1px;
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 0.5px;
    border-color: #EEEEEE;
}

.filter-pagination{
	display: inline-block;
    background: #f9f9f9;
    border-radius: 10px;
    padding: 5px 5px;
}
.filter-pagination .previous-button,
.filter-pagination .next-button{
    font-size: 16px;
    border: 0 !important;
    width: auto;
	padding: 14px 20px;
	margin: 0 5px;
    border-radius: 10px;
    display: inline-block;
    background-color: #fff;
	color: #7e7e7e;
}
.filter-pagination button:hover{
	background:	#2f4cdd;
	color:#fff;
}
.filter-pagination button[disabled]{
	opacity:0.5;
	cursor: default;
}
.filter-pagination button[disabled]:hover{
	background:#2f363e;
	opacity:0.5;
	color:#fff;
}
.table-index input[type=number] {
    background:#f9f9f9 !important;
    color: #7e7e7e;
    width: 70px;
    border: 0;
    background-color: #f8f8f8;
    padding: 6px 0 6px 10px;
}
@media only screen and (max-width: 575px) {

    .filter-pagination .previous-button,
    .filter-pagination .next-button{
        padding:8px 10px;
        font-size:14px;
    }
}

@media (max-width: 500px) {
  .appointment-card {
    min-width: 600px;
  }
}
