
:root{
  --primary: #0CC0DE !important;
}
a{
  text-decoration: none !important;
}

.user-select{
    border: 1px solid #f0f1f5 !important;
    padding-top: 16px !important;
    padding-bottom: 16px !important;
    border-radius: 12px !important;
}
select:required:invalid {
    color: gray;
  }
  .css-yk16xz-control{
    padding-top: 9px !important;
    padding-bottom: 9px !important;
    padding-left: 10px !important;
    border: 1px solid #f0f1f5 !important;
    border-radius: 12px !important;

}
.css-1pahdxg-control{
    padding-top: 9px !important;
    padding-bottom: 9px !important;
    border-color: #2684ff !important;
    border-radius: 12px !important;
    box-shadow: 0 0 0 0 #2684ff !important;
}
.react-switch {
    vertical-align: middle;
    margin-left: 4px;
  }
  .text-font-style{
    font-size: 18px;
    font-weight: 600;
    color: black;
  }

  .react-tel-input{
    width: auto !important;
  }
  .react-tel-input .special-label {
    display: none;
    position: absolute;
    z-index: 1;
    font-size: 13px;
    left: 25px;
    top: -7px;
    background: #fff;
    padding: 0 5px;
    white-space: nowrap;
}
  .react-tel-input .form-control{
    width: 110px !important;
    padding: 24px 9px 24px 60px !important;
    border: none !important;
color: gray !important;
  }
  .sign-phone{
    padding-left: 111px;
  }
  .react-tel-input .country-list{
    width: 250px !important;
  }
  .login-page-logo {
      font-size: 28px;
      font-weight: 500;
      margin: 0px;
    
      background: linear-gradient(90deg, #0CC0DE 1.1%, #2ed1c3 100%);
      /* background:#0CC0DE , linear-gradient(90deg, #0CC0DE 1.10%, #2ED1C3 100%); */
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    }

    /* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
.btn-primary {
  color: #fff;
  background-color: #0CC0DE !important;
  border-color: #0CC0DE !important;
}
.header-right .header-profile > a.nav-link{
  background: #0CC0DE;
}
[data-headerbg="color_1"] .nav-header .hamburger .line {
  background: #0CC0DE !important;
}
.deznav .metismenu > li.mm-active > a{
  color: #0CC0DE !important;

}

[data-headerbg="color_1"] .nav-header .hamburger.is-active .line, [data-headerbg="color_1"] .nav-header .hamburger .line {
  background: #0CC0DE !important;
}
.text-primary {
  color: #0CC0DE !important;
}


.btn-info:not(:disabled):not(.disabled):active, .btn-info:not(:disabled):not(.disabled).active, .show > .btn-info.dropdown-toggle {
  color: #fff;
  background-color: #c395f0 !important;
  border-color: #c395f0 !important;
}
.light.btn-info:hover {
  background-color: #c395f0;
  border-color: #c395f0;
  color: #fff;
}

.pagination .page-item.active .page-link {
  background-color: #0CC0DE ;
  border-color: #0CC0DE ;
  color: #fff;
  box-shadow: 0 10px 20px 0px rgba(47, 76, 221, 0.2);
}
.pagination .page-item .page-link:hover{
  background: #0CC0DE;
}

.header-right .header-profile .dropdown-menu a:hover, .header-right .header-profile .dropdown-menu a:focus, .header-right .header-profile .dropdown-menu a.active {
  color: #0CC0DE;
}
.login-page-bg div {
  border-radius: 23px;
  background: #fff;
  padding: 10px 22px;
}
.login-h {
  -webkit-text-fill-color: transparent;
  font-weight: 500;
  margin: 0;
}
.login-h {
  background: linear-gradient(90deg,#0CC0DE 1.1%,#2ed1c3);
  background: var(--hotelly-purple-to-teal,linear-gradient(90deg,#0CC0DE 1.1%,#2ed1c3));
  background-clip: text;
  -webkit-background-clip: text;
  font-size: 40px;
}

.login-logo-div{
  border-radius: 23px;
  background: #fff;
  padding: 10px 22px;

}
[data-sidebar-style="full"][data-layout="vertical"] .deznav .metismenu > li > a:before{
  background: #0CC0DE;
}
[data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .deznav .metismenu > li.mm-active > a{
  background: #0CC0DE;
}

[data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .deznav .metismenu > li:hover > a{
  background: #0CC0DE;
}
.PhoneInputInput {

  border: 0;
}
.react-tel-input {
  position: absolute !important;
  width: 25% !important;
  left: 2px;
  top: 4px;
}
.relative{
  position: relative;
}

.react-tel-input .flag-dropdown{
  background-color: #fff;
    border: none;
}
.css-13cymwt-control{
  border-radius: 0.75rem !important;
  border: 1px solid #f0f1f5 !important;
}
/* Replace these colors with your preferred values */
.react-google-places-autocomplete__input::placeholder {
  color: gray !important; /* Change the placeholder color */
}
/* @media  screen and (max-width: 1200px){

  .react-tel-input .form-control{
    padding: 12px 9px 13px 60px !important;
  }
   } */



   